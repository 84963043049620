.align-items-center {
    align-items: center;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.text-lg-center {
    @media (min-width: 992px) {
        text-align: center;
    }
}

.text-center {
    text-align: center;
}

.d-none {
    display: none !important;
}
.d-lg-none {
    @media (min-width: 992px) {
        display: none !important;
    }
}
.d-lg-block {
    @media (min-width: 992px) {
        display: block !important;
    }
}
.d-lg-table {
    @media (min-width: 992px) {
        display: table !important;
    }
}

.mb-5 {
    margin-bottom: 30px;

    @media (min-width: 992px) {
        margin-bottom: 0;
    }
}
