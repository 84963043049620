$vermelho: #ff0032;
$cinza: #f5f4f3;
$branco: #fff;

.background-cover {
    background-size: cover;
    background-position: top center;
}
.background-contain {
    background-size: contain;
    background-position: top center;
}

.social {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin: 0;
    padding: 0;

    img {
        width: 24px;
    }
}

.btn {
    align-items: center;
    border: 1px solid $vermelho;
    border-radius: 6px;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    gap: 12px;
    height: 72px;
    justify-content: center;
    line-height: 1;
    margin: auto;
    max-width: 360px;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 992px) {
        font-size: 24px;
        max-width: 430px;
    }

    svg {
        width: 30px;
    }
}

.btn-contained {
    background-color: $vermelho;
    color: $branco;

    &:hover,
    &:focus {
        background-color: $branco;
        color: $vermelho;

        svg {
            path {
                fill: $vermelho;
            }
        }
    }
}

.btn-outlined {
    background-color: transparent;
    color: $vermelho;

    svg {
        path {
            fill: $vermelho;
        }
    }

    &:hover,
    &:focus {
        background-color: $vermelho;
        color: $branco;

        svg {
            path {
                fill: $branco;
            }
        }
    }
}

.btn-doar {
    background-color: $branco;
    color: $vermelho;
    height: 50px;
    margin-top: 20px;
    width: 90px;

    &:hover,
    &:focus {
        background-color: $vermelho;
        color: $branco;
    }
}

.section-title {
    color: $vermelho;
    font-size: 36px;
    font-weight: 500;
    line-height: 48px;
    text-transform: uppercase;

    @media (min-width: 992px) {
        font-size: 40px;
    }
}
.section-content {
    color: #666666;
    font-family: 'Open Sans', sans-serif;
}

.featured-text {
    background-color: $vermelho;
    color: $branco;
    display: block;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;

    @media (min-width: 992px) {
        display: table;
        font-size: 40px;
        line-height: 53px;
        margin: 0 auto 10px;
        max-width: 925px;
        padding: 10px 50px;
    }
}

.swiper-slide {
    height: auto;
}
.swiper-pagination {
    position: initial;
}
.swiper-pagination-bullet {
    background-color: #d7d4d3;
}
.swiper-pagination-bullet-active {
    background-color: $vermelho;
}

.video-introducao {
    background-image: url('#{$image-path}/thumb-video.png');
    background-position: center;
    background-size: cover;
    height: 700px;
    position: relative;
    width: 100%;
}
.video-introducao-play {
    background-image: url('#{$image-path}/play.png');
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 15;
}
.video-background {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.card-item {
    background-color: #f5f4f3;
    border-radius: 12px;
    display: block;
    height: 100%;
    padding: 20px;
    text-decoration: none;

    &.vermelho {
        background-color: $vermelho;
    }
}

.card-icone {
    display: block;
    margin: 0 auto 15px;
    min-height: 40px;
}
.card-titulo {
    color: $vermelho;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
}
.card-preco {
    color: $white;
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;

    span {
        font-size: 45px;
        text-transform: none;
    }
}
.card-texto {
    color: #666666;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    word-wrap: break-word;

    a {
        color: #666666;
        word-wrap: break-word;
    }
}

.introducao {
    padding: 70px 0 50px;

    @media (min-width: 992px) {
        padding: 100px 0 100px;
    }
}

.apoio {
    background-color: $cinza;
    position: relative;

    &:before {
        background-color: $branco;
        content: '';
        display: block;
        height: 250px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }

    .container {
        position: relative;
        z-index: 2;

        .section-title {
            margin-bottom: 50px;
        }
    }
}

.doacao {
    padding: 0 0 30px 0;

    @media (min-width: 992px) {
        padding: 0 0 50px 0;
    }
}

.destaque {
    background-color: $cinza;
    padding: 60px 0;

    @media (min-width: 992px) {
        padding: 80px 0;
    }

    .btn {
        margin-top: 60px;

        @media (min-width: 992px) {
            margin-top: 80px;
        }
    }
}

.numeros {
    background-color: $cinza;
    padding: 60px 0;
}

.formas {
    padding: 60px 0;

    @media (min-width: 992px) {
        padding: 80px 0;
    }

    .section-title {
        margin-bottom: 50px;

        @media (min-width: 992px) {
            margin-bottom: 75px;
        }
    }
}

.cta-card {
    background-color: $vermelho;
    border-radius: 12px;
    color: $branco;
    display: block;
    margin: 50px auto;
    max-width: 940px;
    padding: 35px 30px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    @media (min-width: 992px) {
        // flex-direction: row-reverse;
        display: flex;
        margin: 50px auto 170px;
    }
}
.cta-titulo {
    font-size: 24px;
    line-height: 32px;

    @media (min-width: 992px) {
        font-size: 40px;
        line-height: 48px;
    }
}
.cta-link {
    font-size: 16px;
    font-weight: 500;
    margin: 0;

    @media (min-width: 992px) {
        font-size: 20px;
    }
}
.cta-img {
    display: block;
    margin-top: -100px;
    height: auto;
    max-width: 100%;

    @media (min-width: 992px) {
        bottom: 0;
        margin-top: 0;
        right: 30px;
        position: absolute;
    }
}

.formulario {
    align-items: center;
    background-color: #999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 150px 0 100px;
    min-height: calc(100vh - 242px);
}
.formulario-item {
    display: block;
    margin-bottom: 20px;
    width: 100%;
}
.form-control {
    border: 0;
    border-radius: 6px;
    height: 72px;
    outline: none;
    padding-inline: 10px;
    width: 100%;
}
