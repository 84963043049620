body {
    font-family: 'Oswald', sans-serif;
    -webkit-font-smoothing: antialiased;
}

$font-thin: 100;
$font-extra-light: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;
$font-extra-bold: 800;
$font-black: 900;
